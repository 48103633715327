import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

document.addEventListener("DOMContentLoaded", function () {
	let slideLength_top; //TOP concept　伊方
	let slideLength_activty; //TOP concept　梼原
	let slideLength_shop; //TOP ショップ
	// let slideLength_ucar; //中古車 TOPおすすめスライド


	const elms_top = document.querySelector("#splideIkata");
	if (elms_top) {
		slideLength_top = elms_top.querySelectorAll(".splide__slide").length; //スライドの枚数を取得
		let options_top = {
			type: "fade",
			// perPage: 1,
			autoplay: true, // 自動再生
			interval: 4000, // 自動再生の間隔
			// autoHeight: true,
			speed: 2000,
			rewind: true,
			pagination: true,
			arrows: false,
			drag: false,
			pauseOnHover: false,
			pauseOnFocus: false,
		};
		new Splide('#splideIkata', options_top).mount();
	}

	const elms_activty = document.querySelector("#splideYusuhara");
	if (elms_activty) {
		slideLength_activty = elms_activty.querySelectorAll(".splide__slide").length; //スライドの枚数を取得
		let options_activty = {
			type: "fade",
			// perPage: 1,
			autoplay: true, // 自動再生
			interval: 4000, // 自動再生の間隔
			// autoHeight: true,
			speed: 2000,
			rewind: true,
			pagination: true,
			arrows: false,
			drag: false,
			pauseOnHover: false,
			pauseOnFocus: false,
		};
		// addOptions = {
		// 	arrows: slideLength === 1 ? false : true, //arrowsをfalseにしただけだとスライド自体はできてしまうのでdestroyする↓
		// 	destroy: slideLength === 1 ? "completely" : false //destroy:trueはブレイクポイントを監視する場合。completelyは完全に削除する。
		// };
		// Object.assign(options, addOptions);

		new Splide('#splideYusuhara', options_activty).mount();
	}


	const elms_shop = document.querySelector("#splideShop");
	if (elms_shop) {
		slideLength_shop = elms_shop.querySelectorAll(".splide__slide").length; //スライドの枚数を取得
		let options_shop = {
			type: 'loop',
			drag: 'free',
			focus: 'center',
			// perPage: 4,
			autoScroll: {
				speed: 1,
				pauseOnHover: false,
				pauseOnFocus: false,
			},
			autoWidth: true,
			arrows: false,
			gap: '3rem',
			pagination: false,

		};
		new Splide('#splideShop', options_shop).mount({ AutoScroll });
	}


	// const elms_ucar = document.querySelector(".splide_reco");
	// // console.log(elms_ucar);
	// if (elms_ucar) {
	// 	slideLength_ucar = elms_ucar.querySelectorAll(".splide__slide").length; //スライドの枚数を取得
	// 	// console.log(slideLength_ucar);
	// 	let options_ucar = {
	// 		type: 'loop',
	// 		perPage: 3,
	// 		perMove: 1,
	// 		autoplay: true, // 自動再生
	// 		interval: 4000, // 自動再生の間隔
	// 		autoHeight: true,
	// 		speed: 2000,
	// 		gap: 48,
	// 		pagination: false,
	// 		arrows: slideLength_ucar <= 3 ? false : true, //arrowsをfalseにしただけだとスライド自体はできてしまうのでdestroyする↓
	// 		destroy: slideLength_ucar <= 3 ? "completely" : false, //destroy:trueはブレイクポイントを監視する場合。completelyは完全に削除する。
	// 		breakpoints: {
	// 			640: {
	// 				perPage: 1,
	// 				arrows: slideLength_ucar === 1 ? false : true, //arrowsをfalseにしただけだとスライド自体はできてしまうのでdestroyする↓
	// 				destroy: slideLength_ucar === 1 ? "completely" : false, //destroy:trueはブレイクポイントを監視する場合。completelyは完全に削除する。
	// 			},
	// 		}
	// 	};
	// 	new Splide('.splide_reco', options_ucar).mount();

	// }

});
